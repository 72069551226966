// used in routerConfig

// This doesn't appear to work
export default defineNuxtRouteMiddleware(() => {
  if (process.client) {
    try {
      useCartBCValidate();
    } catch (error: any) {
      console.error(error); // eslint-disable-line no-console
    }
  }
});
